import React from 'react';
import cx from 'classnames';
import env from '@lwe/toolkit/env';
import Image from 'components/Image';

import s from './style.module.css';

const generateParams = (width, height = null, multiplier = 1) => {
  const params = [
    width && `${Math.floor(width * multiplier)}`,
    height && `x${Math.floor(height * multiplier)}`,
  ].join('');

  return params;
};

export const assetUrl = (uuid, width, height, multiplier = 1) => {
  const urlPrefix = `${env('ASSETS_ENDPOINT')}/${uuid}`;
  return `${urlPrefix}/${generateParams(width, height, multiplier)}`;
};

const AssetImage = ({ uuid, width, height, className, ...props }) => {
  const url = assetUrl(uuid, width, height);
  const retinaUrl = assetUrl(uuid, width, height, 2);
  const halfUrl = assetUrl(uuid, width, height, 0.5);
  const quarterUrl = assetUrl(uuid, width, height, 0.25);

  return (
    <Image
      src={url}
      srcSet={`${quarterUrl} ${Math.floor(width / 4)}w, ${halfUrl} ${Math.floor(
        width / 2,
      )}w, ${url} ${width}w, ${retinaUrl} ${width * 2}w`}
      width={width}
      height={height}
      className={cx(s.root, className)}
      fade
      {...props}
    />
  );
};

export default AssetImage;
