import React from 'react';
import useNativeLazyLoading from '@charlietango/use-native-lazy-loading';
import { useInView } from 'react-intersection-observer';

const LazyImage = ({ src, srcSet, eager, ...rest }) => {
  const supportsLazyLoading = useNativeLazyLoading();
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });

  return (
    <img
      {...rest}
      src={inView || supportsLazyLoading || eager ? src : undefined}
      srcSet={inView || supportsLazyLoading || eager ? srcSet : undefined}
      ref={supportsLazyLoading ? undefined : ref}
      loading={eager ? 'eager' : 'lazy'}
    />
  );
};

export default LazyImage;
