import React, { useEffect, useRef, useState } from 'react';
import { assetUrl } from 'components/AssetImage';
import { useMediaQuery } from 'react-responsive';
import Helmet from 'react-helmet';
import { useConfiguration } from 'hooks/configuration';
import videojs from 'video.js';
import 'videojs-mux';
import s from './style.module.css';
import cx from 'classnames';
import 'video.js/dist/video-js.css';
import b from './brand.module.css';

const noop = () => {};

const VideoPlayer = ({
  autoplay = false,
  play = false,
  playerName = 'Generic player',
  onClick,
  showWatchPreview,
  ...props
}) => {
  const [key, setkey] = useState(0);
  const videoNode = useRef(null);
  const videoPlayerRef = useRef(null);
  const { settings } = useConfiguration();
  const { muxDataEnvKey } = settings;

  const {
    hlsURL = null,
    mp4URL = null,
    metaData = {},
    handleVideoPlay,
    onReady = noop,
    poster,
    storyboardUrl,
  } = props;
  const isNotMobile = useMediaQuery({ query: '(min-width: 400px)' });

  const { title = null, series = null, videoId = null, userId = null } = metaData;

  const playHandler = () => handleVideoPlay && handleVideoPlay();

  let src, type;
  if (hlsURL) {
    src = hlsURL;
    type = 'application/x-mpegURL';
  } else {
    src = mp4URL;
    type = 'video/mp4';
  }

  const options = {
    autoplay,
    controls: true,
    preload: 'auto',
    aspectRatio: '16:9',
    liveui: false,
    fluid: true,
    playbackRates: [0.5, 1, 1.5, 2],
    sources: [{ src, type }],
    html5: {
      vhs: {
        limitRenditionByPlayerDimensions: true,
        useDevicePixelRatio: true,
      },
    },
  };

  useEffect(() => {
    if (videoPlayerRef.current) {
      if (play) {
        videoPlayerRef.current.play();
      } else {
        videoPlayerRef.current.pause();
      }
    }
  }, [play]);

  useEffect(() => {
    if (videoNode.current) {
      const player = videojs(videoNode.current, options);

      player.ready(() => {
        onReady();
        player.on('play', () => playHandler());
        player.mux({
          debug: process.env.NODE_ENV !== 'production',
          data: {
            env_key: muxDataEnvKey,
            viewer_user_id: userId,
            player_name: playerName,
            player_init_time: Date.now(),
            video_id: videoId,
            video_title: title,
            video_series: series,
            video_duration: (player.duration() % 1) * 1000,
          },
        });
        if (play) {
          player.play();
        }
      });

      videoPlayerRef.current = player;

      return () => {
        if (!module.hot) {
          player.dispose();
        }
        setkey(key + 1);
      };
    }
  }, [options.sources.src]);

  // TODO: this should be moved outside of here, its specific to one video slot.
  const posterURL = poster
    ? isNotMobile
      ? assetUrl(poster.uuid, 924, 527, 2)
      : assetUrl(poster.uuid, 400, 228, 2)
    : undefined;

  // Note: The video element is deliberately wrapped in a div, as
  // cleanup fails when switching lesson if it's the parent node.

  return (
    <>
      {poster && <Helmet link={[{ rel: 'preload', as: 'image', href: posterURL }]} />}
      <div
        onClick={onClick}
        className={cx(s.root, b.root, { [b.showWatchPreview]: showWatchPreview })}
      >
        <div data-vjs-player key={`video-${key}`}>
          <video ref={videoNode} poster={posterURL} className="video-js"></video>
        </div>
      </div>
    </>
  );
};

export default VideoPlayer;
