import React, { useState } from 'react';
import LazyImage from 'components/LazyImage';
import cx from 'classnames';
import s from './style.module.css';

const Image = (props) => {
  const [loaded, setLoaded] = useState(false);
  const { className, onLoad, fade, ...rest } = props;

  const handleLoaded = () => {
    setLoaded(true);
    onLoad();
  };

  return (
    <LazyImage
      onLoad={handleLoaded}
      className={cx(s.root, { [s.loaded]: loaded, [s.fade]: fade, [className]: className })}
      {...rest}
    />
  );
};

Image.defaultProps = {
  onLoad: () => {},
  fade: false,
  src: null,
  className: null,
};

export default Image;
